

















































































import { defineComponent, computed } from '@vue/composition-api';
import { FCCart } from '@fc/angie-ui';
import { IVariant, IProduct } from '~/types/fightcamp';
import { currency2 } from '~/plugins/filters';
import FCImage from '~/components/FCImage.vue';
import { useCartContext } from '~/composables/useCartContext';

export default defineComponent({
  name: 'MarketplaceCart',
  components: {
    FCCart,
    FCImage,
  },
  setup() {
    // @ts-ignore
    const { $store } = useNuxtApp();
    const {
      summaryTotal, cartItems, insertItemToCart, removeItemFromCart, cartqty,
    } = useCartContext();

    const displayableItems = computed(() => cartItems.value.filter((item) => item.type === 'equip' || item.type === 'deal' || item.type === 'warranty'));

    return {
      cartqty: computed(() => (cartqty.value === 1 ? `${cartqty.value} item` : `${cartqty.value} items`)),
      summaryTotal,
      displayableItems,
      monthlyFinancing: computed(() => $store.getters.monthlyFinancing),
      processingAddToCart: computed(() => $store.state.checkout.processingAddToCart),
      getButtonStyle: computed(() => (displayableItems.value.length === 0 ? 'button-disabled' : 'button-primary')),
      insertItemToCart,
      removeItemFromCart,
      getImageUrl: (product: IProduct) => getImageUrl(product),
      getProvider: (item: IVariant) => (item.product.img_url && isStoryblokImage(item.product.img_url) ? 'storyblok' : 'fcProvider'),
      displayAddRemove: (item: IVariant) => (!(item.product.category === 'package' || item.product.category === 'warranty' || item.type === 'deal')),
      itemDisplayPrice: (item: IVariant) => (item.price === 0 ? 'Free' : currency2(item.price)),
    };
  },
});

function getImageUrl(product: IProduct) {
  const url = product.marketplace_img_url || product.img_url;
  if (isStoryblokImage(url)) return url;
  if (isFightCampImage(url)) return url.split('https://cdn3.joinfightcamp.com/1000x0')[1];

  return url || false;
}

function isStoryblokImage(url: string) {
  return url.includes('a.storyblok.com');
}

function isFightCampImage(url: string) {
  return url.includes('joinfightcamp.com');
}
